<template>
  <div class="recommond-page">
    <div class="invite-top-bg hidden-xs hidden-sm"></div>
    <div class="card">
      <div class="text-grey-dark title-md text-bold">
        {{ $t("invite_way") }}
      </div>
      <div class="text-grey text-md mt-20">
        {{ $t("invite_way_tip") }}
      </div>
      <div class="mt-20">
        <el-row :gutter="20">
          <el-col :span="18">
            <input type="text" class="invite-link" :value="cpText" />
          </el-col>
          <el-col :span="6"
            ><el-button
              type="primary"
              style="width: 100%"
              @click="copy(cpText)"
              >{{ $t("key_to_copy") }}</el-button
            ></el-col
          >
        </el-row>
      </div>
      <div class="text-grey text-md mt-20">
        {{ $t("invite_way_tip2") }}
      </div>
      <div class="text-grey text-md mt-20">
        <el-row :gutter="20">
          <el-col :span="6"><img :src="inviteImgLink" alt=""/></el-col>
          <el-col :span="18">
            <div class="text-grey title-md mt-20">
              {{ $t("invite_way_tip3") }}
            </div>
            <el-button
              class="mt-20"
              type="primary"
              style="width: 300px"
              @click="down(cpText)"
              >{{ $t("download_qr") }}</el-button
            >
          </el-col>
        </el-row>
      </div>
    </div>
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="card recommond-my" style="height: 630px">
          <div class="text-grey-dark title-md text-bold">
            {{ $t("my_invication") }}
          </div>
          <el-row>
            <el-col :span="12">
              <div class="text-orange title-md text-bold mt-20">
                {{ recommendData.friendCount }}{{ $t("people") }}
              </div>
              <div class="text-grey-dark text-md text-bold">
                {{ $t("my_invication_tip") }}
              </div>
            </el-col>
            <el-col :span="12"
              ><div class="text-orange title-md text-bold mt-20">
                {{ recommendData.moneyCount }}{{ $t("yuan") }}
              </div>
              <div class="text-grey-dark text-md text-bold">
                {{ $t("my_invication_tip1") }}
              </div></el-col
            >
          </el-row>
          <el-input
            style="margin-top: 10px"
            @keyup.enter.native="searchByFriendName(friendName)"
            :placeholder="$t('friend_accounts')"
            v-model="friendName"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="searchByFriendName(friendName)"
            ></el-button>
          </el-input>
          <el-table
            :stripe="true"
            :data="FriendsList"
            :empty-text="$t('my_invication_tip2')"
            v-loading="recommendLoading"
            style="width: 100%; margin-top: 10px"
          >
            <el-table-column
              :show-overflow-tooltip="true"
              prop="userName"
              :label="$t('friend_accounts')"
              width="180"
            >
            </el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="registryTime"
              :label="$t('registry_time')"
              width="120"
            >
            </el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="contribution"
              :label="$t('my_invication_tip3')"
              width="80"
              :formatter="contributionFormat"
            >
            </el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="closedSurveyUnqualifiedRate"
              :label="$t('volum_rate')"
              width="80"
              :formatter="percentFormat"
            >
            </el-table-column>
            <!-- <el-table-column  :show-overflow-tooltip="true"   prop="lastLoginDate" label="最后登录时间" width="120">
            </el-table-column> -->
          </el-table>
          <div class="mt-20">
            <el-pagination
              background
              layout="prev, pager, next,total"
              :total="pagination.totalSize"
              :page-size="pagination.pageSize"
              :current-page="pagination.currentPage"
              @current-change="changePage"
            >
            </el-pagination>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="card" style="height: 630px">
          <div class="text-grey-dark title-md text-bold">
            {{ $t("re_ranking") }} {{ rankMonth }} {{ $t("month") }}
          </div>
          <el-table
            :stripe="true"
            :data="rankList"
            style="width: 100%; margin-top: 20px"
            v-loading="rankLoading"
          >
            <el-table-column
              :show-overflow-tooltip="true"
              prop="ranking"
              :label="$t('rank')"
            >
            </el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="displayName"
              :label="$t('user_nickname')"
            >
            </el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="amount"
              :label="$t('re_people_num')"
            >
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <div class="card">
      <div class="text-grey-dark title-md text-bold">
        {{ $t("activity_rule") }}
      </div>
      <div class="text-grey text-md mt-20">
        {{ $t("activity_rule_tip1") }}
      </div>
      <div class="text-grey text-md mt-20">
        {{ $t("activity_rule_tip2") }}
      </div>
      <div class="text-grey text-md mt-20">
        {{ $t("activity_rule_tip3") }}
      </div>
      <div class="text-grey text-md mt-20">
        {{ $t("activity_rule_tip4") }}
      </div>
    </div>
  </div>
</template>
<style src="./index.scss" lang="scss"></style>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      dialogVisible: false,
      rankLoading: false,
      rankMonth: "-",
      FriendsLoading: false,
      recommendLoading: false,
      rankList: [],
      FriendsList: [],
      inviteLoding: false,
      friendName: "",
      pagination: {
        pageSize: 6,
        totalSize: 0,
        currentPage: 1
      },
      recommendData: {
        friendCount: 0, //推荐好友总数
        moneyCount: 0
      }
    };
  },
  async created() {
    this.getRankList();
    this.getFriendList();
  },
  methods: {
    handleClose() {},
    changePage(v) {
      this.getRecommendInfo(v);
    },
    getRankList() {
      this.rankLoading = true;
      this.$http
        .get("/api/v1/Ranking", {})
        .then(rank => {
          this.rankList = rank.data.list.map((d, index) => ({
            ...d,
            ranking: index + 1
          }));
          this.rankMonth = rank.data.month;
          this.rankLoading = false;
        })
        .catch(() => {
          {
            this.rankLoading = false;
          }
        });
    },
    searchByFriendName(v) {
      this.getRecommendInfo(1, v);
    },
    async getFriendList() {
      this.FriendsLoading = true;
      this.getRecommendInfo();
      this.$http
        .get(`/api/v1/Accounts/${this.account.id}/Recommend`)
        .then(res => {
          this.recommendData = res.data;

          this.FriendsLoading = false;
        });
    },
    async getRecommendInfo(v, userName = "") {
      this.recommendLoading = true;
      const res = await this.$http.get(
        `/api/v1/Accounts/${this.account.id}/Friends`,
        {
          params: {
            page: v || this.pagination.currentPage,
            size: this.pagination.pageSize,
            userName
          }
        }
      );

      this.FriendsList = res.data.list;
      this.pagination = res.data.pagebar;
      this.recommendLoading = false;
    },
    copy(text) {
      this.$copy(text);
    },
    down() {
      window.open(this.inviteImgLink);
    },
    // eslint-disable-next-line no-unused-vars
    contributionFormat(row, col) {
      return row.contribution + ` ${this.$t("gold")}`;
    },
    // eslint-disable-next-line no-unused-vars
    percentFormat(row, col) {
      var str = Number(row.closedSurveyUnqualifiedRate * 100).toFixed(0);
      str += "%";
      return str;
    }
  },
  computed: {
    ...mapGetters(["account"]),
    inviteImgLink() {
      return (
        (window.location.origin ||
          window.location.protocol +
            "//" +
            window.location.hostname +
            (window.location.port ? ":" + window.location.port : "")) +
        `/inviteImgDown.aspx?uid=${this.account.id || ""}`
      );
    },
    cpText() {
      return (
        (window.location.origin ||
          window.location.protocol +
            "//" +
            window.location.hostname +
            (window.location.port ? ":" + window.location.port : "")) +
        `/?showLogin=true&isRegistry=true&uid=${this.account.id || ""} ` +
        this.$t("activity_rule_tip5")
      );
    }
  }
};
</script>
